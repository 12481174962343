@font-face {
  font-family: 'Texta';
  src: url('../fonts/texta-regular.woff2') format('woff2'),
    url('../fonts/texta-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Texta';
  src: url('../fonts/texta-bold.woff2') format('woff2'),
    url('../fonts/texta-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: auto;
}

body {
  font-family: 'Texta', sans-serif;
  font-size: 16px;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: currentColor;
}


.option_question {
  width: 49%;
  margin: 0 0.5% 0.5% ;
  display: inline-block;
}

.ant-col.ant-col-24.option_question_dv {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-wrap: wrap;
}

.tagCategory {
  height: 45px !important;
}

.Addquestionbxmodal .ant-modal-header,.commonbxmodal  .ant-modal-header,
.Addnewtestbook .ant-modal-header,.Editquestionbxmodal  .ant-modal-header  {
  background: #0D0C0C;
  color: rgb(255 255 255);
}

.Addquestionbxmodal .ant-modal-header .ant-modal-title,
.Addnewtestbook .ant-modal-header .ant-modal-title,
.Editquestionbxmodal  .ant-modal-header .ant-modal-title,.commonbxmodal  .ant-modal-header  .ant-modal-title{
  color: rgb(255 255 255);
  background: #0D0C0C;
  font-weight: bold;
  font-size: 18px;
}

.Addquestionbxmodal .ant-modal-close ,.Addnewtestbook .ant-modal-close ,.Editquestionbxmodal .ant-modal-close,
.commonbxmodal  .ant-modal-close-icon,.commonbxmodal .ant-modal-close  {color: rgb(255 255 255);}

label.correct_anwes {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  display: block;
  width: 100%;
  color: black;
}input.tag-input {
  margin: 0 0 1rem;
}.ant-switch-inner {
  color: #000000;
}

.ant-switch-checked {
    background-color: #889922;
    color: #fff
}
    
    
.ant-switch-checked .ant-switch-inner {
  color: #fff;
}

.codex-editor svg {
  fill: #7e7e7e;
  stroke: black;
}
.ct{z-index: 9999;}
.ce-toolbox__button {
  color: #000000;}